body {
  line-height: 1.5;

  td,
  th {
    font-family: "Open Sans", sans-serif;
  }
}
header {
  width: 100%;
}

.form-control {
  height: auto;
}

.border-less-image-gallery ks-modal-gallery .plain-container .image {
  border: none;
}

.h-back-user-list {
  color: $primary-color !important;
}

@media only screen and (max-width: 575px) {
  .pcoded-header .dropdown .dropdown-menu {
    left: 0 !important;
  }

  wizard.vertical .vertical a {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .filter-bar .card-task .task-board {
    float: none;
    margin-top: 25px;
  }
}

.pcoded-header .dropdown.show:before {
  left: -10px !important;
}

div.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

#timer {
  color: #ffffff;
  b {
    font-size: 24px;
  }
}

pre code {
  width: auto;
  min-width: 100%;
  margin-left: -80px !important;
}

.btn.btn-sm {
  padding: 3px 12px;
  border-radius: 0.15rem;
}

.btn.btn-lg {
  padding: 12px 25px;
  border-radius: 0.35rem;
}

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  -webkit-box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}
